<template>
	<div class="welcome">

		<div class="welcome__avatar">
			<v2-profile-icon />
		</div>
		<div class="welcome__text">
			<div class="welcome__text--name">
				Welcome back, {{ getFirstName }}!
			</div>
			<!-- <div class="welcome__text--banner">
				Get Started (2/4)
			</div> -->
		</div>

	</div>
</template>

<script>
	import V2ProfileIcon  from '@/components/v2/profile/V2ProfileIcon';

	export default {
		components: { V2ProfileIcon },

		computed: {
			getFirstName () {
				return this.$store.getters['auth/getFirstName'];
			}
		}
	};
</script>

<style lang="scss" scoped>
.welcome {
  width: 100%;
  display: flex;
  align-items: center;

  &__avatar {
    width: 73px;
    height: 73px;
    margin-right: 24px;

    ::v-deep .profile-icon svg {
      width: 41px;
      height: 41px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 10px;

    &--name {
      @include font(34px, $c-dark-navy, 500, 41px);
    }

    &--banner {
      border-radius: 2px;
      padding: 6px 16px;
      @include font(16px, $c-white-d, 500);
      background: $c-dark-navy;
      height: 33px;
      width: 165px;
    }
  }

  @media (max-width: 1279px) {
    height: 65px;

    &__avatar {
      width: 65px;
      height: 65px;
      margin-right: 16px;

      ::v-deep .profile-icon svg {
        width: 31px;
        height: 31px;
      }
    }

    &__text {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      &--name {
        font-size: 24px;
        line-height: 31px;
      }

      &--banner {
        font-size: 14px;
        line-height: 18px;
        height: 30px;
        width: 147px;
      }
    }
  }
}
</style>
