<template>
	<router-link
		:to="`/my-courses/${course.id}`"
		class="course-card"
	>
		<div class="course-card__image">
			<img :src="imageUrl" alt="" />
		</div>
		<div class="course-card--body">
			<div class="course-card__text">
				<p class="course-card__text--title">
					{{ course.title }}
				</p>
				<div class="course-card__info">
					<div class="course-card__info--block">
						<icon icon="v2-shield" colour="transparent" />
						{{ getLevel }}
					</div>
					<div class="course-card__info--block">
						<icon icon="v2-book" colour="transparent" />
						{{ course.lessonCount }} Lessons
					</div>
				</div>
			</div>

			<div class="course-card__status">
				DONE
				<div class="round-wrapper">
					<icon icon="v2-tick" colour="transparent" />
				</div>
			</div>

		</div>

	</router-link>
</template>

<script>
	import Icon                            from '@/components/ui/Icon';
	import routeParams                        from '@/mixins/routeParams';

	export default {
		components: {
			Icon
		},
		mixins: [routeParams],
		props: {
			course: {
				type: Object,
				default: undefined
			}
		},
		computed: {

			imageUrl () {
        if (this.course?.media?.[0]?.url) {
          return this.course.media[0].url;
        }

				const focusTypes = {
					bidding: 'Bidding',
					'declarer play': 'Play',
					defence: 'Defence'
				};

				const focus = focusTypes[this.course.focus[0]] || 'Other';

				return `/card-images/${focus}_Lev${this.course.maxExperienceLevel}.png`;
			},

			getLevel () {
        if (this.course.minExperienceLevel === this.course.maxExperienceLevel) {
          return this.course.minExperienceLevel;
        }

        return `${this.course.minExperienceLevel}-${this.course.maxExperienceLevel}`;
			}

		}
	};

</script>

<style lang="scss" scoped>
	.course-card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 94px;
    border: 1px solid $c-gray-d2;
    background: $c-white-l;
    border-radius: 4px;
    padding: 18px 20px;
    gap: 16px;

    &__image {
      width: 75px;
      height: 57px;
      border-radius: 2px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      gap: 10px;

      &--title{
        @include font(18px, $c-dark-navy, 500, 23px);
        @include text-overflow;
      }
    }

    &__info {
      display: flex;
      gap: 6px;
      align-items: center;
      height: 26px;

      &--block {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        gap: 4px;
        background: $c-white-l2;
        @include font(14px, $c-darkest-navy, 500, 18px);
      }
    }

    &__status {
      display: flex;
      align-items: center;
      gap: 6px;
      background: $c-light-navy;
      height: 26px;
      padding: 4px 8px;
      border-radius: 2px;
      margin-left: auto;
      @include font(14px, $c-white-l, 700, 18px);

      .round-wrapper {
        width: 16px;
        height: 16px;
        background: $c-green;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
      }
    }

    @media (max-width: 1279px) {
      height: 123px;
      padding: 20px;

      &__image {
        width: 81px;
        height: 81px;
      }

      &--body {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }

      &__text {
        gap: 6px;

        &--title {
          font-size: 16px;
          line-height: 20px;
        }
      }

      &__info {
        gap: 4px;
      }

      &__status {
        margin-left: 0;
      }

    }

  }

</style>
